import { RichTextRenderer } from "@webiny/react-rich-text-renderer"
import React, { useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import Asset from "../../asset/asset"

export default function TabTemplateCard({ a, tab, isMobile }) {

  const [open, setOpen] = useState(false)

  // get column class based on content being null
  const getColClass = () => {
    if (a?.content) {
      return "4"
    } else {
      return "12"
    }
  }

  return (
    <Card className={`mb-3 ${a.key !== tab && !isMobile ? "d-none" : ""}`} key={a.key}>

      {isMobile ? (
        <div className="accordion-card-title" onClick={() => setOpen(!open)}>
          <h2>{a.title}</h2>
        </div>
      ) : (
        ""
      )}
      
      <div className="accordion-card-body" aria-expanded={open || !isMobile}>
        <Row>
          <Col xs="12" lg={getColClass()}>
            <Asset a={a} />
          </Col>
          {a?.content ? (
          <Col xs="12" lg="8">
            <div className="p-4">
              {a?.content ? <RichTextRenderer data={a.content} /> : ""}
            </div>
          </Col>
          ) : ""}
        </Row>
      </div>
      
    </Card>
  )
}
