import React, { useEffect, useState } from "react"
import ClassroomLayout from "../../layout/classroom-layout"
import { graphql } from "gatsby"
import TopicHeader from "../topic-header"
import { Container, Row, Col } from "react-bootstrap"
import { getTopicData, loadAssets } from "../topic-helper"
import TabTemplateCard from "./tabtemplate-card"
import { useWindowSize } from "../../../../utils/hooks"
import { useDispatch } from "react-redux"
import { setContextSidebar } from "../../../../redux/app-actions"

export default function TabTemplate4x8(props) {
  const dispatch = useDispatch()
  
  const { pageContext } = props
  const { breadcrumb, next, previous } = pageContext
  const { Course, Module, ParentTopic, Topic } = getTopicData(props)

  const size = useWindowSize()

  const [isMobile, setIsMobile] = useState(0)

  useEffect(() => {
    if (size) {
      setIsMobile(size.width <= 992)
    }
  }, [size])

  const assets = loadAssets(Topic)

  const [tab, setTab] = useState(0)
  const tabClick = (e, key) => {
    e.preventDefault()
    setTab(key)
  }

  useEffect(() => {
    dispatch(
      setContextSidebar({
        course: Course,
        module: Module,
        parentTopic: ParentTopic,
        topic: Topic,
      })
    )
  }, [dispatch])

  return (
    <ClassroomLayout>
      <TopicHeader
        next={next}
        previous={previous}
        breadcrumb={breadcrumb}
        topic={Topic}
      ></TopicHeader>

      <Container>
        <Row>
          {!isMobile ? (
            <Col md="auto">
              <ul className="card card-tabs">
                {assets?.map(a => (
                  <li key={a.id}>
                    <a
                      className={a.key === tab ? "active" : ""}
                      href="#"
                      onClick={e => tabClick(e, a.key)}
                    >
                      {a.title}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          ) : (
            ""
          )}
          <Col>
            {assets.map(a => (
              <TabTemplateCard key={a.id} a={a} tab={tab} isMobile={isMobile} />
            ))}
          </Col>
        </Row>
      </Container>
    </ClassroomLayout>
  )
}

export const query = graphql`
  query ($id: ID, $cid: ID, $mid: ID, $ptid: ID = "") {
    scocms {
      getTopic(where: { id: $id }) {
        ...topicFragment
      }

      getParentTopic: getTopic(where: { id: $ptid }) {
        ...parentTopicFragment
      }

      getCourse(where: { id: $cid }) {
        ...courseFragment
      }

      getModule(where: { id: $mid }) {
        ...moduleFragment
      }
    }
  }
`
